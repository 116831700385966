@use 'sass:math';
@import '../../styles/_meta';

.block {
	display: inline-flex;
	font-weight: 400;
	align-items: center;
	padding-left: 6px;
	padding-right: 6px;
	font-size: 14px;
	height: 24px;
	background-color: rgba($clr-base, 0.04);
	border-radius: 32px;
	line-height: 24px;
	box-sizing: border-box;
	cursor: default;

	&_bg {
		&_green {
			background-color: $clr-color;
		}
		&_white {
			background-color: #fff;
		}
	}

	&_border {
		&_gray {
			border: 1px solid #e0e0e1;
		}
	}

	&_size {
		&_small {
			font-size: 12px;
			height: 20px;
			line-height: 20px;
		}
	}
}

.text {
	white-space: nowrap;
}

.leftIcon {
	fill: #fba72a;
	@include mr(2px);
}
