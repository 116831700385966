@use 'sass:math';
@import 'meta';
@import './states/large.scss';
@import './adaptive/default.scss';
@import './adaptive/homepage.scss';

.wrap {
	display: flex;
	align-items: top;
	min-height: 48px;
	padding: 8px 8px 12px;
	flex-wrap: wrap;
}

.search {
	order: 2;
	width: 100%;
	min-width: 0;
}

.rightSide {
	display: flex;
	margin-left: auto;
	margin-bottom: 8px;
	padding-right: 8px;
}

.logo {
	display: block;
	@include mr(15px);
	margin-bottom: 16px;
	padding-left: 16px;
	fill: #0404067a;
	user-select: none;
	padding-top: 6px;

	&_active {
		cursor: pointer;
		@include hover('hover') {
			&:hover {
				fill: #8835f4;
			}
		}
	}
}

.mobileNonSticky {
	@include breakpoint(null, 'medium') {
		position: relative;
	}
}

.nonSticky {
	position: relative;
}

.desktopNonSticky {
	@include breakpoint('x-large') {
		position: relative;
	}
}

.filterBtn {
	display: none;
	@include mr(4px);
}

.homeForm {
	@include mt(8px);
	position: relative;
	z-index: 2;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 158px;
	&:after {
		content: '';
		height: 160px;
		width: 160px;
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		background-size: cover;
		background-image: url('/img/illustrations/ill-woman-220.svg');
		z-index: -1;
	}
	&_adapt {
		@include breakpoint($from: 375) {
			margin-top: 0;
		}
		@include breakpoint($from: 768) {
			padding-bottom: 40px;
			padding-right: 348px;
			margin-left: 0;
			max-width: 880px;
			margin-left: auto;
			margin-right: auto;
			box-sizing: border-box;
			&:after {
				width: 300px;
				height: 300px;
				left: auto;
				right: 0;
				bottom: 68px;
				transform: none;
			}
		}
	}
}

.homeWrap {
	height: auto;
	&::before {
		background-color: #6621e8;
	}
}

.block_filters {
	.filterBtn {
		display: block;
	}
}

.block_state_large {
	@include state_large();
}

.block_adapt_default {
	@include adapt_default();
}

.block_adapt_homepage {
	@include adapt_homepage();
}
