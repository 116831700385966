@use 'sass:math';
@import 'meta';

.block {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: top;
	cursor: pointer;
	user-select: none;
	box-sizing: border-box;
	border-radius: 50%;

	&_plain {
		width: 32px;
		height: 32px;
		@include hover('hover') {
			&:not(.block_disabled):hover {
				background-color: #8835f40f;
				.icon {
					fill: #8835f4;
				}
			}
		}
		&:not(.block_disabled):active {
			background-color: #8835f4;
			.icon {
				fill: #ffffff;
			}
		}
	}

	&_small,
	&_medium,
	&_large {
		border: 1px solid #0404063d;
		box-shadow: 0px 2px 6px 0px #0000001f;
		background-color: #fff;
		@include hover('hover') {
			&:not(.block_disabled):hover {
				border: 2px solid #8835f4;
				.icon {
					fill: #8835f4;
				}
			}
		}
		&:not(.block_disabled):active {
			background-color: #8835f4;
			.icon {
				fill: #ffffff;
			}
		}
	}

	&_small {
		width: 32px;
		height: 32px;
	}

	&_medium {
		width: 48px;
		height: 48px;
		.icon {
			width: 18px;
			height: 18px;
		}
	}

	&_large {
		width: 64px;
		height: 64px;
		.icon {
			width: 20px;
			height: 20px;
		}
	}

	&_disabled {
		cursor: default;
		border-color: #04040614;
		box-shadow: none;
		.icon {
			opacity: 0.12;
		}
	}
}

.icon {
	width: 16px;
	height: 16px;
	fill: #040406;
	vertical-align: top;
	transform-origin: 50% 50%;
	&_direction {
		&_right {
			transform: rotate(0deg);
		}
		&_left {
			transform: rotate(180deg);
		}
	}
}
