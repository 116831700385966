@use 'abstracts/variables';

@mixin appearance_firstId {
	position: relative;

	&::before {
		content: '';

		z-index: -1;
		position: absolute;
		top: -8px;
		right: -8px;
		bottom: -20px;
		left: -8px;
		width: 100%;
		height: 100%;
		padding: 8px 8px 20px;

		border-radius: 20px;
		background: linear-gradient(
				177.23deg,
				rgba(variables.$clr-blue-violet, 0.06) -13.49%,
				rgba(variables.$clr-neon-blue, 0.06) 109.75%
			),
			$clr-invert;
	}

	.infoTag {
		display: inline-block;
	}
}
