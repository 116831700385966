@mixin state_cols() {
	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 28px;
	}
	.items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(auto-fit);
		gap: 24px;
	}
	.item {
		box-sizing: border-box;
		margin: 0;
	}
	.imgHolder {
		min-height: 98px;
	}
}
