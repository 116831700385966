@mixin state_long-plates() {
	.item {
		display: flex;
		align-items: center;
		padding-top: 24px;
	}

	.contentHolder {
		text-align: left;
		margin-top: 0;
	}

	.imgHolder {
		flex: 0 0 108px;
		max-width: 108px;
		@include mr(32px);
	}

	.title {
		font-size: 24px;
		font-weight: 600;
		line-height: 28px;
	}
}
