@import 'meta';

.block {
	background-color: #040406;
}

.wrap {
	@extend %content_center;
	color: #ceacfa;
	padding-top: 32px;
	// height: 260px;
	padding-bottom: 96px;
	box-sizing: border-box;
}

.wrapContent {
	padding-left: 8px;
	padding-right: 8px;
}

.copyright {
	@include mb(16px);
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
}

.description {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.mapIcon {
	width: 25px;
	height: 25px;
	fill: #fff;
}

.btns {
	position: absolute;
	bottom: 16px;
	left: 50%;
	transform: translateX(-50%);
}

.mapBtn {
	position: absolute;
	left: 50%;
	bottom: 0;
	display: flex;
	padding: 8px 20px;
	color: white;
	align-items: center;
	border: 2px solid #ffffff;
	border-radius: 80px;
	cursor: pointer;
	transform: translateX(-50%);

	@include hover('hover') {
		&:hover {
			background-color: #8835f4;
		}
	}
}

span.mapLabel, //css wars
.mapLabel {
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	@include ml(8px);
}

.btns_fixed {
	position: fixed;
	z-index: map-get($layers, 'sticky');
	display: flex;
	left: 0;
	right: 0;
	transform: none;

	:global(.--scroll-compensator) & {
		margin-right: var(--scroll-bar-width);
	}

	.mapBtn {
		margin: 0 auto;

		background-color: rgba($clr-base, 0.64);
		@include hover('hover') {
			&:hover {
				background-color: #8835f4;
			}
		}
	}
}

.links {
	margin-top: 16px;
}

.linkItem {
	&:not(:last-child) {
		margin-right: 8px;
		&:after {
			content: '•';
			color: #ceacfa7a;
			margin-left: 8px;
		}
	}
}

.link {
	position: relative;
	color: #ceacfa;
	border-bottom: 1px solid #ceacfa;
	font-size: 14px;
	line-height: 16px;

	@include onhover() {
		&:hover {
			opacity: 0.7;
		}
	}
}
