@use 'sass:math';
@import '../../styles/meta';

.block {
	display: block;
	&:hover {
		@include hover('hover') {
			.arrows {
				opacity: 1;
			}
		}
	}
}

.wrap {
	position: relative;
	border-radius: 16px;
	border: 1px solid #04040629;
	overflow: hidden;
	background-color: rgba(4, 4, 6, 0.04);
	transform: translateZ(0);
	box-sizing: border-box;
}

.slideWrap {
	z-index: -1;
	transform: translateZ(0);
}

.track {
	box-sizing: border-box;
	display: flex;
	width: 100%;
}

.img {
	width: 100%;
	height: auto;
	background-color: $clr-img;
}

.slide {
	width: 100%;
	flex-shrink: 0;
	z-index: -1;
}

.arrows {
	position: absolute;
	top: 50%;
	margin-top: -15px;
	z-index: 1;
	transform: translateZ(100px);

	&_left {
		left: 12px;
	}

	&_right {
		right: 12px;
	}

	&_partially-visible {
		opacity: 0 !important;
	}

	opacity: 0;
	transition: 0.15s opacity;
}

.dots {
	position: absolute;
	bottom: 16px;
	left: 50%;
	height: 9px;
	z-index: 10;
	transform: translate3d(-50%, 0, 100px);
}

.placeholder {
	display: block;
	min-height: 1px;
	&::before {
		content: '';
		display: block;
		padding-bottom: calc(2 / 3 * 100%);
	}
}

.link {
	display: block;
}

.mainLink {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;
}
