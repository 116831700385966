@use 'conf';

@mixin state_theme_stroke {
	border-style: solid;
	border-width: 1px;
	border-color: #d7d9e0;

	&:hover {
		border-color: conf.$clr-purp;
	}

	&:focus-within {
		border-color: conf.$clr-purp;
		box-shadow: 0px 0px 0px 4px rgba(conf.$clr-purp, 0.12);
	}
}
