@import 'meta';

.box {
	padding: 32px 0;
	&_events {
		padding-top: 40px;
		padding-bottom: 48px;
		@include breakpoint($to: 768) {
			padding-top: 32px;
			padding-bottom: 40px;
		}
	}
}

.about {
	margin-top: 40px;
	@include breakpoint($from: 768) {
		margin-top: 48px;
	}
}

.popular {
	position: relative;
	margin-left: -8px;
	margin-right: -8px;
	padding: 32px 8px;

	&:before {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		top: 0;
		bottom: 0;
		background-color: #f6f6f8;
		z-index: -1;
	}

	@include breakpoint(375, 600) {
		margin-left: -16px;
		margin-right: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}

	@include breakpoint(600, 960) {
		margin-left: -32px;
		margin-right: -32px;
		padding-left: 32px;
		padding-right: 32px;
	}
}

.slider-track-mix {
	@include breakpoint($to: 375) {
		margin-left: -8px;
		margin-right: -8px;
		padding: 0 8px;
		&_events {
			padding: 0 4px;
		}
	}

	@include breakpoint(375, 600) {
		margin-left: -16px;
		margin-right: -16px;
		padding: 0 8px;
		&_events {
			padding: 0 12px;
		}
		&_popular {
			padding: 0 16px;
		}
	}

	@include breakpoint(600, 960) {
		margin-left: -32px;
		margin-right: -32px;
		padding: 0 24px;
		&_events {
			padding: 0 28px;
		}
		&_popular {
			padding: 0 32px;
		}
	}
}
