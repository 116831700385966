@use 'sass:math';
@import '../../styles/meta';
@import './states/small.scss';

.block {
	@extend %purple_link;
	display: inline-flex;
	align-items: center;
	vertical-align: top;
	&:hover .rightIcon {
		fill: $clr-purp;
	}

	&_type {
		&_thin {
			cursor: pointer;
			fill: $clr-purp;
			color: $clr-purp;

			.linkText {
				font-weight: 400;
				font-size: inherit;
				line-height: inherit;
				border-bottom: 1px solid transparent;
				position: relative;
				bottom: -1px;
			}

			&:hover {
				color: $clr-purp;
				fill: $clr-purp;
				@include hover('hover') {
					.linkText {
						border-bottom-color: $clr-purp;
					}
				}
			}
		}
	}
}

.rightIcon {
	@include ml(4px);
	fill: #aeaeaf;
	vertical-align: middle;

	&_pointer {
		width: 18px;
		height: 12px;
	}

	&_rightArrow {
		width: 12px;
		height: 12px;
		fill: inherit;
	}
}

.linkText {
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
}

.block_small {
	@include state_small();
}
