@use 'conf';
@use 'abstracts/mixins.scss';

@use './states/layout_extended.scss' as *;
@use './states/layout_cloud.scss' as *;
@use './states/layout_simple.scss' as *;

.block {
	position: relative;
	width: 100%;
	z-index: 1;
}

.menu {
	@include mixins.disable-scrollbar();

	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0 12px;
	width: 100%;

	background-color: conf.$clr-invert;

	&_layout_extended {
		@include state_layout_extended;
	}

	&_layout_cloud {
		@include state_layout_cloud;
	}

	&_layout_simple {
		@include state_layout_simple;
	}
}

.item {
	&:not(:last-child) {
		border-bottom-style: solid;
		border-bottom-color: rgba(conf.$clr-base, 0.06);
		border-bottom-width: 1px;
	}
}

.button {
	@include mixins.text-overflow();

	margin-left: 0;
	max-width: 100%;
	padding: 13px 0;
	width: 100%;
	vertical-align: middle;

	text-align: left;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;

	&:hover {
		color: conf.$clr-purp;

		.icon {
			background-color: rgba(conf.$clr-purp, 0.08);
			fill: conf.$clr-purp;
		}
	}

	&:disabled {
		cursor: not-allowed;

		.text,
		.value {
			color: rgba(conf.$clr-base, 0.4);
		}

		.icon {
			fill: rgba(conf.$clr-base, 0.16);
			background-color: rgba(conf.$clr-base, 0.02);
		}
	}
}

.icon {
	display: none;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-right: 12px;

	background-color: rgba(conf.$clr-base, 0.04);
	border-radius: 8px;

	fill: rgba(conf.$clr-base, 0.56);

	float: left;
}

.text,
.value {
	display: block;
}

.text {
	margin-top: 6px;

	color: rgba(conf.$clr-base, 0.48);
	font-size: 12px;
	line-height: 12px;
	font-weight: 400;
}

.tip {
	padding: 12px 4px;
	border-bottom-width: 0;

	color: rgba(conf.$clr-base, 0.48);
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
}

.nothing {
	@extend .tip;

	padding: 12px 0;
	color: conf.$clr-red;
}
