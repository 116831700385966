.tag {
	display: inline-flex;
	align-items: center;
	height: 32px;
	padding: 0 10px;
	border-radius: 32px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
		inset 0 0 0 1px rgba(4, 4, 6, 0.12);
	cursor: pointer;
	font-size: 13px;
	font-weight: 500;
	line-height: 100%;
	&:hover {
		color: #8835f4;
	}
	&[aria-checked='true'] {
		color: #8835f4;
		background-color: #fff;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 2px #8835f4;
	}
}
