@import 'meta';

.title {
	font-size: 20px;
	line-height: 22px;
	font-weight: 600;
	text-align: center;
	@include mb(12px);
	padding-left: 12px;
	padding-right: 12px;
}

.description {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	color: #040406cc;
	text-align: center;
	padding-left: 12px;
	padding-right: 12px;
}

.slide {
	width: 248px;
	@include mr(16px);
}

.arrow {
	@include mr(8px);
	&_disabled {
		.iconBtn {
			opacity: 0.12;
		}
	}
}

.arrows {
	@include mt(32px);
	text-align: center;
}

.wrapper {
	@include mt(32px);
	padding-bottom: 16px;
}

.block_adapt_default {
	@include breakpoint($from: 600) {
		.title {
			font-size: 24px;
			line-height: 28px;
		}
	}
}
