@mixin state_large {
	min-height: 80px;

	.search {
		width: auto;
		order: 0;
		flex-grow: 1;
	}

	.wrap {
		align-items: center;
		flex-wrap: nowrap;
		padding: 16px;
	}

	.logo {
		margin-bottom: 0;
		padding: 0;
		margin-right: 12px;

		:global(.logo_small) {
			display: none;
		}
		:global(.logo_main) {
			display: block;
		}
	}

	.filterBtn {
		&:not(:last-child) {
			margin-right: 8px;
		}
	}

	.rightSide {
		padding: 0;
		margin-bottom: 0;
		margin-left: 8px;
	}
}
