// CONF

$clr-base: #040406
$clr-base---04: rgba($clr-base, 0.04)
$clr-base---08: rgba($clr-base, 0.08)
$clr-base---12: rgba($clr-base, 0.12)
$clr-base---16: rgba($clr-base, 0.16)
$clr-base---24: rgba($clr-base, 0.24)
$clr-base---32: rgba($clr-base, 0.32)
$clr-base---40: rgba($clr-base, 0.4)
$clr-base---48: rgba($clr-base, 0.48)
$clr-base---56: rgba($clr-base, 0.56)
$clr-base---64: rgba($clr-base, 0.64)
$clr-base---72: rgba($clr-base, 0.72)
$clr-base---80: rgba($clr-base, 0.8)
$clr-base---88: rgba($clr-base, 0.88)
$clr-base---96: rgba($clr-base, 0.96)
$clr-gold: #fba72a
$clr-grey: #f5f5f5
$clr-grey---01: rgba($clr-grey, 0.01)
$clr-grey---88: rgba($clr-grey, 0.88)
$clr-grey-02: #f2f2f3
$clr-invert: #fff
$clr-invert---01: rgba($clr-invert, 0.01)
$clr-invert---24: rgba($clr-invert, 0.24)
$clr-invert---64: rgba($clr-invert, 0.64)
$clr-invert---80: rgba($clr-invert, 0.8)
$clr-invert---88: rgba($clr-invert, 0.88)

$clr-black: #000
$clr-black---06: rgba(#000, 0.06)
$clr-black---10: rgba(#000, 0.10)
$clr-black---12: rgba(#000, 0.12)
$clr-black---20: rgba(#000, 0.20)
$clr-blue: #d4e0fa
$clr-green: #acffbd
$clr-red: #ef1d4f
$clr-pink: #ffe3f8
$clr-pink-02: #ffacf7
$clr-purp: #8835F4
$clr-purp---06: rgba($clr-purp, 0.06)
$clr-purp---08: rgba($clr-purp, 0.08)
$clr-purp---10: rgba($clr-purp, 0.10)
$clr-purp---12: rgba($clr-purp, 0.12)
$clr-purp---16: rgba($clr-purp, 0.16)
$clr-purp---24: rgba($clr-purp, 0.24)
$clr-purp---28: rgba($clr-purp, 0.28)
$clr-purp---48: rgba($clr-purp, 0.48)
$clr-purp---64: rgba($clr-purp, 0.64)
$clr-purp---72: rgba($clr-purp, 0.72)
$clr-purp---88: rgba($clr-purp, 0.88)
$clr-purp---92: rgba($clr-purp, 0.92)
$clr-purp-02: #ceacfa
$clr-wine: #a1007e
$clr-wine-02: #a7318a

$clr-black-shadow: 0 2px 6px $clr-black---12

$clr-img: rgba(4, 4, 6, 0.04)

=clr-smooth($clr-val, $text-smooth: true, $important: false)
	color: if($important, #{$clr-val} !important, $clr-val)

	@if $text-smooth
		+text-smooth

	@if type-of($clr-val) != color
		@error $clr variable type must be color

// CONF___END
