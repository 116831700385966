@use 'sass:meta';
@use 'sass:list';
@use 'sass:map';

/// Breakpoints map
/// @access public
/// @since 0.0.1
/// @group media-queries
/// @type map
/// @prop {string} breakpoints.x-small [415] - Extra small breakpoint
/// @prop {string} breakpoints.small [568] - Small breakpoint
/// @prop {string} breakpoints.medium [768] - Medium breakpoint
/// @prop {string} breakpoints.large [992] - Large breakpoint
/// @prop {string} breakpoints.x-large [1024] - Extra large breakpoint
/// @example scss - Breakpoints declaration
///   $breakpoints: (
///     "x-small": 415,
///     "small": 568,
///     "medium": 768,
///     "large": 992,
///     "x-large": 1024,
///   );
/// @author Artem Kucherenko

$breakpoints: (
	'xx-small': 375,
	'x-small': 414,
	'small': 568,
	's-medium': 600,
	'medium': 768,
	's-large': 960,
	'large': 992,
	'x-large': 1024,
	'xx-large': 1280,
);

/// Примесь для декларации брейкпоинтов.
/// @access public
/// @since 0.0.1
/// @group layout
/// @param {string, map, list, number} $from [null] - Начальная точка медиа-запроса (`min-width`).
/// @param {string, number} $to [null] - Конечная точка медиа-запроса (`max-width`).
/// @param {boolean} $include [false] - Если `true` последний пиксель точки `$to` будет включен в медиа-запрос. Не влияет на точку `$from`.
/// @example scss - медиа-запрос в интервале точек `x-small` и `medium`. Последний пиксель не включен.
///   @include breakpoint($from: "x-small", $to: "medium") {...};
///   // Если "x-small": 320px и "medium": 768px:
///   // @media (min-width: 320px) and (max-width: 767px) {...};
/// @example scss - тот же медиа-запрос, но последний пиксель включен.
///   @include breakpoint($from: "x-small", $to: "medium", $include: true) {...};
///   // Если "x-small": 320px и "medium": 768px:
///   // @media (min-width: 320px) and (max-width: 768px) {...};
/// @example scss - перегрузка аргумента `$from` списком (`list`).
///   $list: (320, 768);
///   @include breakpoint($list) {...};
///   // @media (min-width: 320px) and (max-width: 767px) {...};
/// @example scss - перегрузка аргумента `$from` ассоциативным массивом (`map`).
///   $map: ("from": 320, "to": 768);
///   @include breakpoint($map) {...};
///   // @media (min-width: 320px) and (max-width: 767px) {...};
/// @example scss - использование чисел в качестве точек
///   @include breakpoint($from: 320, $to: 768) {...};
///   // @media (min-width: 320px) and (max-width: 767px) {...};
/// @output Медиа-запрос на основе предоставленных точек.
/// @require {variable} breakpoints - ассоциативный массив имен и значений точек.
/// @author Artem Kucherenko

@mixin breakpoint($from: null, $to: null, $include: false) {
	$raw-from: null;
	$raw-to: null;

	@if (meta.type-of($from) == 'map') {
		$raw-from: map.get($from, 'from');
		$raw-to: map.get($from, 'to');
	}

	@if (meta.type-of($from) == 'list') {
		$raw-from: if(list.length($from) >= 1, list.nth($from, 1), null);
		$raw-to: if(list.length($from) >= 2, list.nth($from, 2), null);
	}

	@if ($from and meta.type-of($from) == 'number') {
		$raw-from: $from;
	}

	@if ($from and meta.type-of($from) == 'string') {
		@if ($from and not map.has-key($map: $breakpoints, $key: $from)) {
			@error "no breakpoint named #{$from}";
		}
		$raw-from: map.get($breakpoints, $from);
	}

	@if ($to and meta.type-of($to) == 'number') {
		$raw-to: $to;
	}

	@if ($to and meta.type-of($to) == 'string') {
		@if ($to and not map.has-key($map: $breakpoints, $key: $to)) {
			@error "no breakpoint named #{$to}";
		}
		$raw-to: map.get($breakpoints, $to);
	}

	@if ($raw-from and $raw-to and $raw-from > $raw-to) {
		@error "$from must be equal or less than $to";
	}

	@if ($raw-from and $raw-to) {
		$raw-to: if($include, $raw-to, $raw-to - 1);
		$query: '(min-width: #{$raw-from}px) and (max-width: #{$raw-to}px)';
		@media #{$query} {
			@content;
		}
	} @else if ($raw-from and not $raw-to) {
		$query: '(min-width: #{$raw-from}px)';
		@media #{$query} {
			@content;
		}
	} @else if ($raw-to and not $raw-from) {
		$raw-to: if($include, $raw-to, $raw-to - 1);
		$query: '(max-width: #{$raw-to}px)';
		@media #{$query} {
			@content;
		}
	}
}
