@mixin adapt_homepage() {
	margin-left: -8px;
	margin-right: -8px;

	.wrap {
		min-height: 0;
	}

	.logo {
		fill: #fff;
		margin-bottom: 0;
	}

	.rightSide {
		margin-bottom: 0;
	}

	@include breakpoint($from: 375) {
		@include state_large();
		.rightSide {
			margin-left: auto;
		}
		.wrap {
			padding-left: 8px;
			padding-right: 8px;
			padding-top: 20px;
		}
		.logo {
			margin-bottom: 0;
			padding: 0;
			margin-right: 12px;

			:global(.logo_small) {
				display: none;
			}
			:global(.logo_main) {
				display: none;
			}
			:global(.logo_medium) {
				display: block;
			}
		}
	}
	@include breakpoint($from: 600) {
		.wrap {
			padding: 20px 8px 16px;
		}
		.logo {
			margin-right: 16px;
		}
		.search {
			flex-grow: 0;
			margin-right: auto;
		}
	}
}
