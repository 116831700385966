@mixin state_map() {
	.wrap {
		display: flex;
		flex-direction: column;
	}
	.address {
		order: -1;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 8px;
		display: block;

		&:before {
			display: none;
		}
	}
	.metro {
		font-size: 16px;
		line-height: 24px;
	}
}
