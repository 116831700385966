@import 'meta';

.block {
	background: linear-gradient(0deg, rgba(4, 4, 6, 0.03), rgba(4, 4, 6, 0.03)),
		linear-gradient(0deg, #ffffff, #ffffff);
}

.content {
	@extend %content_center;
	padding-top: 40px;
	padding-bottom: 40px;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;

	p {
		@include mt(12px);
		@include mb(12px);
	}

	h1 {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		@include mb(8px);
	}

	h2,
	h3,
	h4,
	h5 {
		font-weight: 600;
		@include mt(12px);
		@include mb(6px);
		+ p,
		ul {
			@include mt(6px);
		}
	}

	b {
		font-weight: 600;
	}

	ul {
		padding-left: 22px;
		li {
			position: relative;
			@include mt(6px);
			@include mb(6px);
			&::before {
				content: '';
				position: absolute;
				left: -18px;
				top: 0.55em;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: #040406;
			}
		}
		&:global(.dash) {
			li::before {
				width: 12px;
				top: 0.7em;
				height: 1px;
				border-radius: 0;
			}
		}
	}
}

.contentText {
	padding-left: 8px;
}
