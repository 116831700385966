=mb($val)
	margin-bottom: $val
	&:last-child
		margin-bottom: 0

=mt($val)
	margin-top: $val
	&:first-child
		margin-top: 0

=mr($val)
	margin-right: $val
	&:last-child
		margin-right: 0

=ml($val)
	margin-left: $val
	&:first-child
		margin-left: 0

=onhover()
	&:hover
		@media (hover: 'hover')
			@content

@function em($value, $context: 16)
	@return #{$value / $context}rem

=arrow($width: 20px, $height: 20px, $direction: up, $color: #000)
	width: 0
	height: 0

	// Right
	@if $direction == right
		border-top: ($height / 2) solid transparent
		border-bottom: ($height / 2) solid transparent
		border-left: $width solid $color

	// Left
	@if $direction == left
		border-top: ($height / 2) solid transparent
		border-bottom: ($height / 2) solid transparent
		border-right: $width solid $color

	// Up
	@if $direction == up
		border-left: ($width / 2) solid transparent
		border-right: ($width / 2) solid transparent
		border-bottom: $height solid $color

	// Down
	@if $direction == down
		border-left: ($width / 2) solid transparent
		border-right: ($width / 2) solid transparent
		border-top: $height solid $color

// keyframes mixin
=keyframes($name)
	@keyframes #{$name}
		@content

	@-o-keyframes #{$name}
		@content

	@-moz-keyframes #{$name}
		@content

	@-webkit-keyframes #{$name}
		@content

// animation mixin
=animation($value)
	-webkit-animation: $value
	-moz-animation: $value
	-o-animation: $value
	animation: $value

// animation-play-state mixin
=animation-play-state($value)
	-webkit-animation-play-state: $value
	-moz-animation-play-state: $value
	-o-animation-play-state: $value
	animation-play-state: $value

=text-smooth
	font-smoothing: antialiased
	-webkit-font-smoothing: antialiased

=abs($value: "")
	position: absolute
	margin: auto

	@if $value == ""
		top: 0
		right: 0
		bottom: 0
		left: 0
	@else if $value == "h"
		right: 0
		left: 0
	@else if $value == "v"
		top: 0
		bottom: 0

	// @else
	// 	@warn 'NOT VALID == «`#{$value}`»'

=abs-simple($value: "")
	position: absolute

	@if $value == ""
		top: 0
		right: 0
		bottom: 0
		left: 0
	@else if $value == "h"
		right: 0
		left: 0
	@else if $value == "v"
		top: 0
		bottom: 0

=bg-pseudo($color, $position: relative, $min-width: 320px)
	position: $position

	&::before
		content: ''
		+abs-simple(v)
		width: 100vw
		min-width: $min-width
		+translate-centering
		background-color: $color

	& > *
		z-index: 0

=bg-pseudo-after($color, $position: relative, $min-width: 320px)
	position: $position

	&::after
		content: ''
		+abs-simple(v)
		width: 100vw
		min-width: $min-width
		+translate-centering
		background-color: $color

=noSelect
	user-select: none
	-moz-user-select: none
	-webkit-user-select: none

=flex
	+flex-view(0, 0, auto)
	display: flex
	display: -webkit-flex

=flex-view($grow, $shrink, $basis)
	flex: $grow $shrink $basis
	-webkit-flex: $grow $shrink $basis

=flex-shrink($value: 1)
	flex-shrink: $value
	-webkit-flex-shrink: $value

=flex-grow($value: 1)
	flex-grow: $value
	-webkit-flex-grow: $value

=flex-basis($value, $static: "")
	flex-basis: $value
	-webkit-flex-basis: $value

	@if $static == static
		max-width: $value

=flex-inline
	+flex-view(0, 0, auto)
	display: inline-flex
	display: -webkit-inline-flex

=flex-r
	flex-direction: row
	-webkit-flex-direction: row

=flex-rr
	flex-direction: row-reverse
	-webkit-flex-direction: row-reverse

=flex-c
	flex-direction: column
	-webkit-flex-direction: column

=flex-w
	flex-wrap: wrap
	-webkit-flex-wrap: wrap

=flex-nw
	flex-wrap: nowrap
	-webkit-flex-wrap: nowrap

=justify($value: center)
	@if $value == "start" or $value == "end"
		justify-content: flex-#{$value}
		-webkit-justify-content: flex-#{$value}
	@else if $value == "space-between" or $value == "space-around"
		justify-content: $value
		-webkit-justify-content: $value
	@else
		justify-content: $value
		-webkit-justify-content: $value

		// -moz-box-pack: $value
		// -webkit-box-pack: $value
		// -moz-justify-content: flex-$value
		// box-pack: $value

//
=align-items($value: center)
	@if $value == "start" or $value == "end"
		align-items: flex-#{$value}
		-webkit-align-items: flex-#{$value}
	@else
		align-items: $value
		-webkit-align-items: $value

		// -webkit-box-align: $value1
		// -moz-align-items: $value1
		// box-align: $value1

=align-content($value: center)
	@if $value == "start" or $value == "end"
		align-content: flex-#{$value}
		-webkit-align-content: flex-#{$value}
	@else if $value == "space-between" or $value == "space-around"
		align-content: $value
		-webkit-align-content: $value
	@else
		align-content: $value
		-webkit-align-content: $value

=align-self($value: center)
	@if $value == "start" or $value == "end"
		align-self: flex-#{$value}
		-webkit-align-self: flex-#{$value}
	@else
		align-self: $value
		-webkit-align-self: $value

=order($value)
	order: $value
	-webkit-order: $value

=border-radius($value)
	border-radius: $value
	-moz-border-radius: $value
	-webkit-border-radius: $value

=border-radius-top($value)
	border-top-right-radius: $value
	-moz-border-top-right-radius: $value
	-webkit-border-top-right-radius: $value
	border-top-left-radius: $value
	-moz-border-top-left-radius: $value
	-webkit-border-top-left-radius: $value

=filter($value)
	filter: $value
	-moz-filter: $value
	-webkit-filter: $value

=box-shadow($value...)
	box-shadow: $value
	-moz-box-shadow: $value
	-webkit-box-shadow: $value

=box-sizing($value: border)
	box-sizing: #{$value}-box
	-moz-box-sizing: #{$value}-box
	-webkit-box-sizing: #{$value}-box

=transform($value)
	transform: $value

=transition($value)
	transition: $value
	-moz-transition: $value
	-webkit-transition: $value

=perspective($value)
	perspective: $value
	-webkit-perspective: $value

=transform-origin($value)
	transform-origin: $value
	-moz-transform-origin: $value
	-webkit-transform-origin: $value

=transform-style($value)
	transform-style: $value
	-moz-transform-style: $value
	-webkit-transform-style: $value

=backface-visibility($value)
	backface-visibility: $value
	-moz-backface-visibility: $value
	-webkit-backface-visibility: $value

=background-clip($value: content)
	background-clip: #{$value}-box
	-moz-background-clip: #{$value}-box
	-webkit-background-clip: #{$value}-box

/// Mixin for inserting custom SVG into `background-image` property.
/// Requires `PostCSS`.
/// @access public
/// @since 0.0.1
/// @group image-manipulation
/// @param {string} $src - Name of SVG in `~/src/svg/` folder in `%s.svg` format.
/// @param {color} $value - Color for SVG fill attribute.
/// @example scss - Adding a gold (#FFD700) `star.svg` to `.some-class` `background-image`.
///	.some-class {
///		+postsvg("star", #FFD700)
///	}
/// @output `background-image` with data url of provided SVG.
/// @author Denis Khlyoskin

=postsvg($src, $value)
	background-image: svg("#{$src}", "[fill]: " + $value)

=content-center
	+justify

	text-align: center

=content-left
	+justify(start)

	text-align: left

=content-right
	+justify(end)

	text-align: right

=content-left-c
	+align-items(start)

	text-align: left

=content-right-c
	+align-items(end)

	text-align: right

// box-flex for <IE 10
// Windows phone, <IOS6, <IE10 are not supported

/// Mixin for disabling scrollbars
/// @author Artem Kucherenko
/// @group style
/// @link https://webkit.org/blog/363/styling-scrollbars/ WebKit
/// @link https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width FF
/// @output A set of properties for disabling scrollbars
=disable-scrollbar
	&::-webkit-scrollbar
		// iOS Safari
		display: none
		width: 0
		height: 0

	// COF FF69
		scrollbar-width: none

/// Mixin for translateX-centering elements in MSIE11
/// @author Artem Kucherenko
/// @group MSIE11 hacks
/// @access public
/// @example scss - Centering 'after' pseudo-element
///		@include translate-centering(after)
///	@example scss - Centering 'before' pseudo-element
///		@include translate-centering(before)
///	@example scss - Centering current element
///		@include translate-centering
///		//or
///		@include translate-centering(this)
/// @param {String} $pseudoElement [this] - Name of affected pseudo-element, leave default or 'this' for centering current element
///	@require {mixin} transform
///	@throw Property #{$pseudoElement} must be either before or after.

=translate-centering($pseudoElement: this, $direction: "horizontal")
	$direction: if($direction == "horizontal", "left", "top")
	$coordinate: if($direction == "left", "X", "Y")

	@if $pseudoElement != before and $pseudoElement != after and $pseudoElement != this
		@error "mixin: center-pseudo: Property #{$pseudoElementName} must be either before, after or this."

	@if $pseudoElement == this
		#{$direction}: 50%

		+transform(translate#{$coordinate + "(-50%)"})
	@else
		&::#{$pseudoElement}
			#{$direction}: 50%

			+transform(translate#{$coordinate + "(-50%)"})

=color-initial($fallback)
	@supports (color: initial)
		color: initial

	@if $fallback and type-of($fallback) == color
		color: $fallback

=block-crop($font-size: 15, $max-width: 100%, $line: false)
	overflow: hidden
	display: inline-block !important
	vertical-align: middle
	max-width: $max-width
	line-height: normal
	text-overflow: ellipsis
	font-size: em($font-size)

	&::after
		content: "."
		overflow: hidden
		display: inline-block
		vertical-align: middle
		width: 0

	@if $line == 1
		white-space: nowrap

=backdrop-filter($value: null)
	backdrop-filter: $value
	-webkit-backdrop-filter: $value

=text-valign($font-size: 14)
	display: inline-block !important
	line-height: normal
	vertical-align: middle
	font-size: em($font-size)

	&:not(:empty)::after
		content: "."
		overflow: hidden
		display: inline-block
		width: 0
		vertical-align: middle

/// Selector list
/// @type List
/// @access public
$selectors: "+", "~", ">", " "

/// Content Sectioning HTML elements
/// @type List
/// @access public
/// @group Utilities
$contentSectioning: "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4", "h5", "h6", "hgroup", "main", "nav", "section"

/// TextContent HTML elements list
/// @type List
/// @access public
/// @group Utilities
$textContent: "blockquote", "dd", "div", "dl", "dt", "figcaption", "figure", "hr", "li", "ol", "p", "pre", "ul"

/// Inline Text Semantics HTML elements list
/// @type List
/// @access public
/// @group Utilities
$inlineTextSemantics: "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn", "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp", "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr"

/// Image and Multimedia HTML elements list
/// @type List
/// @access public
/// @group Utilities
$imageAndMultimedia: "area", "audio", "img", "map", "track", "video"

/// Embedded Content HTML elements list
/// @type List
/// @access public
/// @group Utilities
$embeddedContent: "embed", "iframe", "object", "param", "picture", "source"

/// Scripting HTML elements list
/// @type List
/// @access public
/// @group Utilities
$scripting: "canvas", "noscript"

/// Demarcating Edits HTML elements list
/// @type List
/// @access public
/// @group Utilities
$demarcatingEdits: "del", "ins"

/// Table Content HTML elements list
/// @type List
/// @access public
/// @group Utilities
$tableContent: "caption", "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"

/// Forms HTML elements list
/// @type List
/// @access public
/// @group Utilities
$forms: "button", "datalist", "fieldset", "form", "input", "label", "legend", "meter", "optgroup", "option", "output", "progress", "select", "textarea"

/// Interactive HTML elements list
/// @type List
/// @access public
/// @group Utilities
$interactiveElements: "details", "dialog", "menu", "summary"

/// Web Components HTML elements list
/// @type List
/// @access public
/// @group Utilities
$webComponents: "slot", "template"

/// HTML elements list
/// @type List
/// @access public
/// @ignore No head related elements
/// @group Utilities
$htmlElements: "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4", "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div", "dl", "dt", "figcaption", "figure", "hr", "li", "ol", "p", "pre", "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn", "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp", "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "area", "audio", "img", "map", "track", "video", "embed", "iframe", "object", "param", "picture", "source", "canvas", "noscript", "del", "ins", "caption", "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "button", "datalist", "fieldset", "form", "input", "label", "legend", "meter", "optgroup", "option", "output", "progress", "select", "textarea", "details", "dialog", "menu", "summary", "slot", "template"

/// Mixin for setting size properties
/// @access public
/// @example sass - Setting width and height properties separately
///	 +size(100px, 200px)
///	 +size(3, 1) // This will convert unitless values to rem
/// @example sass - Setting equal width and height properties
///	 +size(100px)
///	 +size(3) // This will convert unitless values to rem
/// @example sass - Setting only width property
///	 +size(100px, auto)
/// @group Sizing
/// @author Artem Kucherenko
/// @output width and height properties
/// @param {number | string} $width [auto] - Width property
/// @param {number | string} $height [$width] - Height property
/// @since 0.01

=size($width: auto, $height: $width)
	@if type-of($width) == "number" and unitless($width)
		$width: $width * 1rem

	@if type-of($height) == "number" and unitless($height)
		$height: $height * 1rem

		width: $width
		height: $height

=centeringDot
	&::after
		display: inline-block
		width: 0
		overflow: hidden
		vertical-align: middle
		content: "."

/// Render ruler
/// @access public
/// @example sass - Ruler without args
///	 +ruler()
/// @example sass - Horizontal or vertical ruler with default size
///	 +ruler(horizontal)
///	 +ruler(vertical)
/// @example sass - Ruler with unitless size (converts to px)
///	 +ruler(200)
///	 +ruler(horizontal, 100)
/// @example sass - Ruler with direction and size parameters (arguments order indifferent)
///	 +ruler(vertical, 1000px)
///	 +ruler(200px, horizontal)
/// @group Testing
/// @author Artem Kucherenko
/// @require {mixin} size
/// @output ::before pseudo-element with properties for ruler rendering
/// @param {list} $args [vertical, 600px] - Set of arguments containing direction and size of ruler
/// @since 0.01

=ruler($args...)
	$direction: vertical
	$size: 100%
	$width: 0
	$height: 0

	@each $arg in $args
		@if $arg == "horizontal" or $arg == "vertical"
			$direction: $arg

		@if type-of($arg) == number
			$size: $arg

			@if unitless($size)
				$size: $size * 1px

	@if $direction == "vertical"
		$height: $size
		$width: 1px

	@if $direction == "horizontal"
		$width: $size
		$height: 1px

	&::before
		@if mixin-exists(size)
			+size($width, $height)
		@else
			width: $width
			height: $height

			position: absolute
			opacity: 0.3
			content: ''

/// Mixin for disabling scrollbars
/// @author Artem Kucherenko
/// @group style
/// @link https://webkit.org/blog/363/styling-scrollbars/ WebKit
/// @link https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width FF
/// @output A set of properties for disabling scrollbars

=disableScrollbar
	scrollbar-width: none

	&::-webkit-scrollbar
		display: none
		width: 0

/// Component declaration mixin
/// @access public
/// @author Artem Kucherenko
/// @content [Component cascade styles]
/// @example scss - Declaration of Button component
///	 +component('btn')
/// @example scss - Declaration of Button component with div container
///	 +component('btn', 'div')
/// @example scss - Declaration of nested Button component (for margins and other internal style purposes)
///	 +component('btn', '>')
/// @group Component
/// @output Component attribute selector and its cascade styles
/// @param {String} $name - Component name
/// @param {List} $additional - Additional parameters list (container and nesting selector)
/// @require $htmlElements - List of HTML elements names for container parameter functioning properly
/// @require $selectors - List of CSS selectors for selector parameter functioning properly
/// @require {function} argumentsFromList - For extracting arguments from additional parameters
/// @since 0.01
=component($name, $additional...)
	$container: argumentsFromList($additional, $htmlElements)
	$selector: argumentsFromList($additional, $selectors)

	@if not $container
		$container: ""

	@if not $selector
		$selector: ""

	#{$selector}#{$container}[class^='#{$name}']
		@content

/// Block declaration mixin
/// @access public
/// @author Artem Kucherenko
/// @content [Block styles]
/// @example scss - Declaration of block for Button component
///	 +component('btn')
///		 +block
/// @group Component
/// @output Block attribute selector
/// @require {mixin} component - Block mixin isn't usable out of component mixin
/// @since 0.01
=block
	&:not([class*='__'])
		@content

/// Element declaration mixin
/// @access public
/// @author Artem Kucherenko
/// @content [Element styles]
/// @example scss - Declaration of __txt element for Button component
///	 +component('btn')
///		 +element('txt')
/// @example scss - Declaration of nested __txt element with direct child selector for Button component
///	 +component('btn')
///		 +element('txt', '>')
/// @group Component
/// @output Element attribute selector
/// @param {String} $name - Element name
/// @param {String} $selector - Selector for nesting
/// @require $selectors - List of CSS selectors for selector parameter functioning properly
/// @require {mixin} component - Element mixin isn't usable out of component mixin
/// @since 0.01
=element($name, $selector: "")
	@if $selector != "" and index($selector, $selectors)
		@error Selector in element mixin are not proper selector

	&#{$selector}[class*='__#{$name}']
		@content

/// Modificator declaration mixin
/// @access public
/// @author Artem Kucherenko
/// @content [Modificator styles]
/// @example scss - Declaration of _map modificator for Button component
///	 +component('btn')
///		 +modificator('map')
/// @example scss - Excluded modificators allows scoping for modificators
///	 +component('btn', 'div')
///		 +modificator('map', 'noMedia')
/// @group Component
/// @output Modificator attribute selector and its styles
/// @param {String} $name - Modificator name
/// @param {List} $withoutModificators - List of excluded modificators for scoping
/// @require {mixin} component - Modificator mixin isn't usable out of component mixin
/// @since 0.01
=modificator($name, $withoutModificators...)
	$excludedModificatorsSelector: ""

	@each $modificator in $withoutModificators
		$excludedModificatorsSelector: $excludedModificatorsSelector + ":not([class*='_#{$modificator}'])"

	&[class*='_#{$name}']#{$excludedModificatorsSelector}
		@content
