@use 'sass:math';
@import 'meta';

.block {
	position: relative;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	z-index: 1;
	width: 24px;
	height: 24px;

	&:before {
		content: '';
		position: absolute;
		width: 80px;
		height: 80px;
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(193, 62, 255, 0.42) 0%,
			rgba(245, 181, 255, 0) 100%
		);
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0.1);
		z-index: -1;
		opacity: 0.72;
		transition: 0.2s transform;
	}

	@include hover('hover') {
		&:hover {
			&::before {
				transform: translateX(-50%) translateY(-50%) scale(1);
			}
		}
	}

	&_active {
		.bg {
			fill: #ffacf7;
		}

		.contour {
			stroke: #a1007e;
		}
	}
}

.icon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 24px;
	height: 22px;
	vertical-align: top;
	transition: 0.2s;

	&:active {
		transform: translateX(-50%) translateY(-50%) scale(1.2);
	}
}

.bg {
	fill: #fff;
}

.contour {
	fill: none;
	stroke: #040406;
}
