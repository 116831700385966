@import 'meta';
@import './states/long-plates.scss';
@import './states/cols.scss';

.block {
	display: block;
}

.title {
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
	text-align: center;
	@include mb(32px);
}

.item {
	text-align: center;
	box-shadow: 0px 2px 6px 0px #00000029;
	border-radius: 16px;
	padding: 24px 23px;
	@include mb(16px);
}

.caption {
	font-size: 18px;
	line-height: 20px;
	font-weight: 500;
	@include mb(12px);
}

.contentHolder {
	@include mt(16px);
}

.img {
	max-width: 100%;
	height: auto;
}

.content {
	color: #040406a3;
	font-size: 16px;
	line-height: 24px;
}

.imgHolder {
	display: block;
}

.block_state {
	&_long-plates {
		@include state_long-plates();
	}
	&_cols {
		@include state_cols();
	}
}

.block_adapt {
	&_homepage {
		@include breakpoint($from: 600, $to: 960) {
			@include state_long-plates();
		}
		@include breakpoint($from: 960) {
			@include state_cols();
		}
	}
}
