@use 'conf';

@mixin state_theme_flat {
	border-width: 1px;
	border-style: solid;
	border-color: rgba(conf.$clr-base, 0.24);

	&:hover {
		border-color: conf.$clr-purp;
	}

	&:focus-within {
		border-color: rgba(conf.$clr-purp, 0.72);
	}

	.append {
		margin-right: -4px;
	}
}
