@use 'conf';

@mixin appearance_adv {
	.wrap {
		box-sizing: border-box;
		min-height: 260px;
		border: solid conf.$clr-base 2px;
	}

	.content {
		display: flex;
		flex-direction: column;
		padding: 24px;
		justify-content: center;
	}

	.title {
		padding: 0;
		margin-bottom: 16px;

		pointer-events: none;
	}

	.tags {
		display: none;
	}
}
