@import 'meta';

.main {
	position: relative;
	flex-grow: 1;
}

.searchModal {
	display: block;
}

.headerWrap {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(247, 247, 247, 0.96);
		z-index: -1;
		transition: 0.2s background-color;
	}
	:global(.htmlFixed) &:before {
		background-color: rgba(247, 247, 247);
	}
}

.header {
	display: block;
	&_fix {
		&_default {
			.headerWrap {
				position: fixed;
				left: 0;
				right: 0;
				top: 0;
				z-index: 100;
			}
		}
		&_profile {
			.headerWrap {
				position: relative;
				left: 0;
				right: 0;
				top: 0;
				z-index: 100;
			}
			@include breakpoint($from: null, $to: 768) {
				.headerWrap {
					position: relative;
				}
			}
		}
	}
}

.root {
	display: flex;
	flex-direction: column;
	color: #040406;
	min-width: 320px;
	box-sizing: border-box;
	min-height: 100vh;
}

.centred {
	@extend %content_center;
}

.mainContent {
	@extend %content_center;
	padding-top: 32px;
	padding-bottom: 64px;
}

.modals {
	display: none;
}

.profile {
	display: none;
}
