@use 'sass:math';
@use 'conf';
@import '../../styles/_meta';

@import './states/medium.scss';
@import './states/large.scss';
@import './states/mapMobile.scss';
@import './states/firstId.scss';
@import './states/adv.scss';
@import './states/ellipsis.scss';

.block {
	display: block;
	height: 100%;
	transform: translateZ(0);
}

.placeholder {
	display: block;
	background-color: rgba(4, 4, 6, 0.04);
	border-radius: 16px;
	overflow: hidden;
	border: 1px solid rgba(4, 4, 6, 0.16);
	box-sizing: border-box;
	&::before {
		content: '';
		display: block;
		padding-bottom: calc(2 / 3 * 100%);
	}
}

.wrap {
	display: flex;
	padding: 8px;
	border: 1px solid #0404063d;
	box-shadow: 0px 4px 6px 0px #0000001f;
	border-radius: 16px;
	background-color: #fff;
	min-width: 0;
	height: 100%;
	box-sizing: border-box;

	&_bg_gray {
		background-color: #f6f6f8;
		box-shadow: none;
		border-color: #f6f6f8;
	}
	&_bg_plain {
		background: none;
		box-shadow: none;
		border: none;
		padding: 0;
	}
}

.preview {
	position: relative;
	@include mr(16px);
	flex-basis: 144px;
}

.favour {
	position: absolute;
	width: 18px;
	height: 16px;
	left: 8px;
	top: 8px;
	z-index: 2;
	cursor: pointer;
	transform: translateZ(100px);
}

.image {
	vertical-align: top;
	border-radius: 12px;
	max-width: 100%;
	height: auto;
	min-width: 104px;
	background-color: $clr-img;
}

.imageWrap {
	position: relative;
}

.previewLink {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.tags {
	@include mb(4px);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.tag {
	@include mr(4px);
	margin-bottom: 4px;
}

.title {
	display: inline-flex;
	flex-direction: column;
	@include mb(8px);
	&[href] {
		cursor: pointer;
		&:hover {
			color: $clr-purp;
			@media (hover: none) {
				color: inherit;
			}
		}
		&:active {
			color: $clr-purp;
		}
	}
}

.content {
	flex-basis: 264px;
	flex-grow: 1;
	padding: 4px;
	box-sizing: border-box;
}

.name {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
}

.category {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.desc {
	display: none;
	font-size: 12px;
	font-weight: 500;
	line-height: math.div(17, 12);
	color: rgba($clr-base, 0.64);
}

.address {
	display: none;
	@include mb(6px);
}

.shortAddress {
	font-size: 12px;
	font-weight: 400;
	line-height: math.div(17, 12);
	color: rgba(#040406, 0.56);
}

.moreHolder {
	@include mt(8px);
}

.slider {
	display: none;
}

.infoTag {
	display: none;

	z-index: 1;
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 8px;

	background-color: $clr-invert;
	border-radius: 32px;

	color: $clr-red;
	font-size: 13px;
	font-weight: 500;
	line-height: 70%;

	pointer-events: none;
}

.block_state_medium {
	@include state_medium();
	@include state_ellipsis();
}

.block_state_large {
	@include state_large();
}

.block_state_tiny {
	.preview {
		@include mr(12px);
		flex-basis: 104px;
	}
}

.block_adapt_mapLayer {
	@include breakpoint(null, 's-medium') {
		@include state_map-mobile();
	}
}

.block_adapt_profileMore {
	@include state_medium();
	@include state_ellipsis();
}

.block_appearance_firstId {
	@include appearance_firstId();
}
.block_appearance_adv {
	@include appearance_adv();
}
