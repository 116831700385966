/// Примесь для использования медиа-запроса `hover`.
/// @access public
/// @since 1.53.0
/// @group media-queries
/// @param {string} $value ["none"] - значение медиа-запроса.
/// @example scss - Основное применение.
///   &:hover {
///     @include hover("hover") {
///       color: $clr-purp;
///     }
///   }
/// @output медиа-запрос с указанным значением.
/// @author Artem Kucherenko
/// @content селекторы, правила и свойства для применения с примесью.

@mixin hover($value: 'none') {
	@if ($value != 'hover' and $value != 'none') {
		@error "hover value must be 'hover' or 'none', instead get '#{$value}'";
	}

	@media (hover: $value) {
		@content;
	}
}
