@use 'sass:map';
@use 'conf';
@use 'abstracts/variables.scss';
@use 'abstracts/mixins.scss';

.block {
	position: relative;
}

.content {
	@include mixins.disable-scrollbar();

	z-index: map.get(variables.$layers, 'popover');
	box-sizing: border-box;
	width: 100%;
	padding: 8px 0;

	overflow-x: hidden;
	overflow-y: scroll;

	min-width: 120px;
	max-height: 300px;

	border: 1px solid rgba(conf.$clr-purp, 0.64);
	border-radius: 16px;
	background-color: conf.$clr-invert;
	box-shadow: 0px 4px 8px rgba(conf.$clr-black, 0.2);

	&_noborder {
		border: 1px solid #04040629;
	}
	&_maxContent {
		width: max-content;
	}
}
