@use 'sass:math';
@import 'meta';
@import './states/map.scss';

.block {
	display: inline-block;
	vertical-align: top;
}

.metro {
	display: inline-flex;
	vertical-align: top;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 8px;
	line-height: 1;

	@include mr(8px);
}

.metros {
	@include mr(4px);
}

.color {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	display: inline-block;
	@include ml(2px);
	vertical-align: top;
	position: relative;
	top: 1px;
}

.colors {
	display: inline-flex;
	align-items: center;
}

.metroName {
	@include ml(4px);
}

.address {
	@extend %with_bullet;
	@include mr(4px);
	display: inline-flex;
	margin-bottom: 6px;
	font-weight: 500;
	font-size: 12px;
	line-height: 1;
	vertical-align: text-bottom;
}

.addressText {
	&_landmark {
		@include mr(4px);
		&:after {
			content: ' –';
			color: rgba(#040406, 0.32);
		}
	}
}

.mapLink {
	@include mr(4px);
	@extend %purple_link;
	@extend %with_dash;
	display: inline-flex;
	font-weight: 400;
	font-size: 14px;
	line-height: 1;
	margin-bottom: 6px;
	vertical-align: text-bottom;
}

.wrap {
	margin-bottom: -6px;
}

.block_state_map {
	@include state_map;
}
