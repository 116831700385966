@use 'sass:math';
@import 'meta';

.block {
	display: inline-block;
}

.logo {
	display: inline-block;
	vertical-align: top;

	svg {
		vertical-align: top;
	}
	&_small {
		display: inline-block;
	}

	&_medium {
		display: none;
	}

	&_main {
		display: none;
	}
}
