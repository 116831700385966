@mixin state_small() {
	.linkText {
		font-size: 12px;
	}

	.rightIcon {
		&_pointer {
			width: 14px;
			height: 10px;
		}
	}
}
