@use 'conf.sass';

/// Breakpoints map
/// @access public
/// @since 0.0.1
/// @group media-queries
/// @type map
/// @prop {string} breakpoints.x-small [415] - Extra small breakpoint
/// @prop {string} breakpoints.small [568] - Small breakpoint
/// @prop {string} breakpoints.medium [768] - Medium breakpoint
/// @prop {string} breakpoints.large [992] - Large breakpoint
/// @prop {string} breakpoints.x-large [1024] - Extra large breakpoint
/// @example scss - Breakpoints declaration
///   $breakpoints: (
///     "x-small": 415,
///     "small": 568,
///     "medium": 768,
///     "large": 992,
///     "x-large": 1024,
///   );
/// @author Artem Kucherenko

$breakpoints: (
	'x-small': 414,
	'small': 568,
	'medium': 768,
	'large': 992,
	'x-large': 1024,
	'xx-large': 1280,
);

// Colors
// #region

/// _Black Russian_ color with `opacity: 0.03`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-base---03;
/// @require {variable} clr-base - _Black Russian_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-base---03: rgba(conf.$clr-base, 0.03);

/// _Black Russian_ color with `opacity: 0.06`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-base---06;
/// @require {variable} clr-base - _Black Russian_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-base---06: rgba(conf.$clr-base, 0.06);

/// _Black Russian_ color with `opacity: 0.10`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-base---10;
/// @require {variable} clr-base - _Black Russian_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-base---10: rgba(conf.$clr-base, 0.1);

/// _Black Russian_ color with `opacity: 0.32`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-base---32;
/// @require {variable} clr-base - _Black Russian_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-base---32: rgba(conf.$clr-base, 0.32);

/// _Black Russian_ color with `opacity: 0.40`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-base---40;
/// @require {variable} clr-base - _Black Russian_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-base---32: rgba(conf.$clr-base, 0.4);

/// _White_ color with `opacity: 0.0001`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-invert---0001;
/// @require {variable} clr-invert - _White_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-invert---0001: rgba(conf.$clr-invert, 0.0001);

/// _White_ color with `opacity: 0.01`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-invert---01;
/// @require {variable} clr-invert - _White_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-invert---01: rgba(conf.$clr-invert, 0.01);

/// _White_ color with `opacity: 0.32`.
/// @access public
/// @since 1.5.4
/// @group colors
/// @type color
/// @example scss - Base usage
///   border-color: $clr-invert---32;
/// @require {variable} clr-invert - _White_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-invert---32: rgba(conf.$clr-invert, 0.32);

/// _White_ color with `opacity: 0.88`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-invert---88;
/// @require {variable} clr-invert - _White_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-invert---88: rgba(conf.$clr-invert, 0.88);

/// _White_ color with `opacity: 0.9`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-invert---90;
/// @require {variable} clr-invert - _White_ base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-invert---90: rgba(conf.$clr-invert, 0.9);

/// `$clr-purp` color with `opacity: 0.64`.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-purp---64;
/// @require {variable} clr-purp - `$clr-purp` base color used in `rgba()` CSS function.
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-purp---64: rgba(conf.$clr-purp, 0.64);

/// _Atlantis_ base color.
/// Used in coloring names and icons of Moscow subway stations.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-green-subway;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-green-subway: #a4c93f;

/// _Violet Blue_ base color.
/// Used in coloring names and icons of Moscow subway stations.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-purple-subway;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-purple-subway: #984b94;

/// _Islamic Green_ base color.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-green-islamic;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-green-islamic: #00c308;

/// _Medium Sea Green_ base color.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-medium-sea;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-medium-sea-green: #2ecb5a;

/// _Eggplant_ base color.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-eggplant;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-eggplant: #a2007e;

/// _Raical Red_ base color.
/// Used in coloring profile ratings.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-rating-bad;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-rating-bad: #ff4869;

/// _Orange_ base color.
/// Used in coloring profile ratings.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-rating-medium;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-rating-medium: #f0ad00;

/// _Islamic Green_ base color.
/// Used in coloring profile ratings.
/// @alias clr-green-islamic
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-rating-good;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-rating-good: $clr-green-islamic;

/// _Lightning Yellow_ base color.
/// Used in coloring profile ratings.
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   color: $clr-rating-gold;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-rating-gold: #fba72a;

/// _Dodger Blue_ base color
/// Used in coloring caret in Homepage inputs
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   caret-color: $clr-caret-color;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-caret-home: #0079fc;

/// _Onahau_ base color
/// Used in `:focus-within` pseudo-class in Homepage inputs
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-image: linear-gradient(
///     0deg,
///     $clr-input-focus-home,
///     $clr-input-focus-home
///   );
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-input-focus-home: #c2f0ff;

/// _Lavender_ base color
/// Used in `border` of `.homepage-event`
/// @access public
/// @since 0.0.1
/// @group colors
/// @type color
/// @example scss - Base usage
///   border: 1px solid $clr-lavender;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-lavender: #e4e2f0;

/// _Ghost White_ base color
/// Used in `linear-gradients` of `.hs-wrapper.popular`
/// @access public
/// @since 1.2.0
/// @group colors
/// @type color
/// @example scss - Base usage
///   linear-gradient(270deg, $clr-ghost-white 0%, transparent 100%);
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-ghost-white: #f7f7f8;

/// _Dark Pastel Green_ base color
/// Used in `:hover` state of `.button.green`
/// @access public
/// @since 1.5.4
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-dark-pastel-green;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-dark-pastel-green: #09ad37;

/// _Dark Violet_ base color
/// Used in `PurpleMedium` button for hover.
/// @access public
/// @since 1.50.4
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-dark-violet;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-dark-violet: #9000cf;

/// _White Smoke_ base color.
/// Used in `bg-pseudo` for `<header>`.
/// @access public
/// @since 1.50.3
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-white-smoke;
/// @author Artem Kucherenko
/// @author Sergey Seleznev
$clr-white-smoke: #f7f7f7;

/// _Medium Orchid_ base color.
/// Used in `.icon.heart-glow` `filter`.
/// @access public
/// @since 1.52.0
/// @group colors
/// @type color
/// @example scss - Base usage
///   filter: drop-shadow(0 0 20px $clr-medium-orchid);
/// @author Artem Kucherenko
/// @author Sergei Seleznev
$clr-medium-orchid: #c13eff;

/// _Blue Violet_ base color.
/// @access public
/// @since 1.52.?
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-blue-violet
/// @author Artem Kucherenko
/// @author Sergei Seleznev
$clr-blue-violet: #9c2cf3;

/// _Neon Blue_ base color.
/// @access public
/// @since 1.52.?
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-neon-blue
/// @author Artem Kucherenko
/// @author Sergei Seleznev
$clr-neon-blue: #3a49f9;

/// _Mauve_ base color.
/// @access public
/// @deprecated Deprecation related message*
/// @since 1.54.?
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-mauve
/// @author Artem Kucherenko
/// @author Sergei Seleznev
$clr-mauve: #c89eff;

/// _Jaguar_ base color.
/// @access public
/// @since 1.54.?
/// @group colors
/// @type color
/// @example scss - Base usage
///   background-color: $clr-jaguar;
/// @author Artem Kucherenko
/// @author Sergei Seleznev
$clr-jaguar: #1a1921;
// #endregion

$clr-color: #acffbd;

/// Buttons sizes.
/// @access private
/// @since 1.1.0
/// @group sizes
/// @type map
/// @prop {number|map} button-sizes.x-large [64px] - XL button size.
/// @prop {number|map} button-sizes.large [56px] - L button size.
/// @prop {number|map} button-sizes.medium [("size": 48px, "padding": 0 16px)] - M button size.
/// @prop {number|map} button-sizes.small [40px] - S button size.
/// @prop {number|map} button-sizes.x-small [("size": 32px, "padding": 0 10px)] - XS button size.
/// @example scss - Basic Button sizes declaration
///   $button-sizes: (
///     "x-large": 64px,
///     "large": 56px,
///     "medium": 48px,
///     "small": 40px,
///     "x-small": 32px,
///   );
/// @example scss - You can declare sizes as map with `padding` property
///   $button-sizes: (
///     "x-large": 64px,
///     "large": 56px,
///     'medium': (
///       'size': 48px,
///       'padding': 0 16px,
///     ),
///     "small": 40px,
///     "x-small": 32px,
///   );
/// @author Artem Kucherenko

$button-sizes: (
	'x-large': 64px,
	'large': 56px,
	'medium': (
		'size': 48px,
		'padding': 0 16px,
	),
	'small': 40px,
	'x-small': (
		'size': 32px,
		// was "0 10px"
		'padding': 0 8px,
	),
	'xx-small': (
		'size': 24px,
		'padding': 0,
		'font-size': 12px,
		'font-weight': 400,
		'line-height': 12px,
	),
);

$layers: (
	'modal': 1000,
	'sticky': 500,
	'popover': 1500,
	'map': 750,
);

$bullet: '•';
$dash: '–';
