@mixin adapt_home() {
	@include breakpoint($from: 375) {
		&::before {
			background-image: url('/img/home_form_bg_600.svg');
		}
	}
	@include breakpoint($from: 600) {
		.title {
			font-size: 24px;
			line-height: 28px;
		}
	}

	@include breakpoint($from: 768) {
		&::before {
			background-image: url('/img/home_form_bg_768.svg');
		}
		.title {
			text-align: left;
			font-size: 34px;
			line-height: 38px;
			padding-right: 8px;
		}
	}

	@include breakpoint($from: 960) {
		.formHolder {
			max-width: 944px;
			margin-left: auto;
		}
		&::before {
			background-image: url('/img/home_form_bg_960.svg');
		}
	}

	@include breakpoint($from: 1024) {
		&::before {
			background-image: url('/img/home_form_bg_1280.svg');
		}
	}
}
