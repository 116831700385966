@use 'conf';

@mixin state_layout_simple {
	padding: 0;

	.item:not(:last-child) {
		border: none;
	}

	.button {
		padding: 8px 16px;

		&:hover {
			color: conf.$clr-base;
			background-color: rgba(conf.$clr-purp, 0.08);
		}
	}

	.value {
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
	}
}
