@mixin state_layout_cloud {
	.list {
		display: flex;
		flex-wrap: wrap;
		column-gap: 4px;
		row-gap: 6px;
		padding: 4px 0;
	}

	.item:not(:last-child) {
		border: none;
	}
}
