@mixin state_ellipsis() {
	.title {
		max-width: 100%;
	}

	.name,
	.category {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
