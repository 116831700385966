@use 'sass:math';
@import 'meta';

.block {
	display: block;
	@include hover('hover') {
		&:hover {
			.wrap {
				box-shadow: inset 0 0 0 2px #8835f4;
			}
		}
	}
	&:active {
		.wrap {
			background-color: #8835f4;
		}
		.icon {
			fill: #fff;
		}
		.node {
			color: #fff;
			&:after {
				color: rgba(255, 255, 255, 0.24);
			}
			&_purple {
				color: rgba(255, 255, 255, 0.64);
			}
		}
	}
}

.icon {
	min-width: 16px;
	width: 16px;
	height: 18px;
	@include mr(10px);
	vertical-align: top;
}

.wrap {
	display: flex;
	padding: 2px 16px;
	min-height: 48px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
	border-radius: 40px;
	align-items: center;
	cursor: pointer;
	user-select: none;
	background: #fff;
	box-sizing: border-box;
}

.content {
	display: flex;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	flex-wrap: wrap;
	min-width: 0;
}

.node {
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(:last-child)::after {
		content: '•';
		margin-left: 2px;
		margin-right: 2px;
		color: rgba(136, 53, 244, 0.24);
	}

	&_purple {
		color: #8835f4;
	}
}
