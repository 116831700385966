@import 'meta';

.block {
	display: block;
}

.slide {
	width: 140px;
	min-width: 0;
	flex: 0 0 auto;
	margin: 12px;
}

.wrapper {
	position: relative;
	max-width: 896px;
	margin-left: auto;
	margin-right: auto;
}

.arrow {
	@include mr(8px);
	&_disabled {
		.iconBtn {
			cursor: alias;
			opacity: 0.12;
		}
	}

	&_nocoarse {
		@media (pointer: coarse) {
			display: none;
		}
	}
}

.arrows {
	text-align: center;
	@include mt(16px);
}

.curtain {
	position: absolute;
	width: 64px;
	top: 0;
	bottom: 0;
	z-index: 1;
	&_right {
		right: 0;
		background: linear-gradient(
			270deg,
			#fff 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	&_left {
		left: 0;
		background: linear-gradient(
			90deg,
			#fff 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
}

.heading {
	margin: 0 12px;
	text-align: center;
	font-size: 24px;
	line-height: 28px;
	font-weight: 600;
	@include mb(32px);
}

.text {
	margin: 16px 12px 40px;
	text-align: center;
	color: rgba($clr-base, 0.8);
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.item img {
	object-fit: cover;
}

.block_adapt_homepage {
	@include breakpoint($to: 600) {
		.heading {
			font-size: 20px;
			line-height: 22px;
		}
	}
	@include breakpoint($to: 960) {
		.slide {
			width: 112px;
			margin: 0 4px 8px;
		}
		.arrows {
			@include mt(24px);
		}
	}

	@include breakpoint(960) {
		.slide {
			margin: 0 8px 16px;
		}
	}
}
