@use 'sass:math';
@import '../../styles/meta';
@import './states//purple.scss';

.block {
	display: inline-flex;
	vertical-align: top;
}

.wrap {
	position: relative;
	display: inline-flex;
	vertical-align: top;
	overflow: hidden;
	max-width: 50px;
	height: 8px;
}

.items {
	position: relative;
	left: 0;
	display: flex;
	align-items: center;
	transition: 0.2s left;
}

.item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 4px;
	height: 4px;
	flex: 0 0 auto;
	font-size: 0px;
	border-radius: 50%;
	box-sizing: border-box;
	border: 1px solid #040406;
	background-color: rgba(#fff, 0.24);
	@include mr(4px);
	cursor: pointer;
	transition: 0.2s width, 0.2s height;

	&_active {
		width: 8px;
		height: 8px;
		background-color: #fff;
	}

	&_transparent {
		background-color: rgba(#fff, 0.24);
	}

	&_subActive {
		width: 6px;
		height: 6px;
	}
}

.block_state_purple {
	@include state_purple();
}

.block_small {
	max-width: 44px;
}
