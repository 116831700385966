@use 'sass:math';
@import '../../styles/meta';

.wrap {
	display: inline-flex;
	padding: 0 16px;
	height: 44px;
	border-radius: 32px;
	align-items: center;
	justify-content: center;

	&_full {
		width: 100%;
		box-sizing: border-box;
	}

	&_shadow {
		&_default {
			box-shadow: 0px 2px 6px 0px #00000029;
		}
	}

	&_bg {
		&_white {
			background-color: #fff;
		}

		&_purple {
			background: linear-gradient(
				92.09deg,
				rgba(210, 83, 255, 0.88) 0%,
				rgba(117, 57, 242, 0.88) 100%
			);
			@include hover('hover') {
				&:hover {
					background: #8835f4;
				}
			}
		}
		&_plainPurple {
			background: #9145f4;
			@include hover('hover') {
				&:hover {
					background: #6227ae;
				}
			}
		}
		&_black {
			transition: 0.2s all;
			background: #040406;
			@include hover('hover') {
				&:hover {
					background: #acffbd;
					color: #040406;
					fill: #040406;
					stroke: #040406;
				}
			}
		}
	}

	&_color {
		&_purple {
			color: #8835f4;
			fill: #8835f4;
			stroke: #8835f4;
		}
		&_white {
			color: #fff;
			fill: #fff;
			stroke: #fff;
		}
	}

	&_border {
		&_grey {
			border: 1px solid #04040629;
			&:hover {
				border: 1px solid #8835f4;
				box-shadow: 0 0 0 1px #8835f4;
			}
		}
	}

	&_round {
		width: 44px;
		height: 44px;
		padding: 0;
	}
}

.label {
	line-height: inherit;
}

.icon {
	width: 22px;
	height: 22px;
	fill: inherit;
	vertical-align: top;
	@include mr(4px);
	@include ml(4px);
	&_size {
		&_small {
			width: 16px;
			height: 16px;
			@include mr(8px);
		}
	}
}
