%content_center {
	display: block;
	min-width: 320px;
	max-width: 1280px;
	margin: 0 auto;
	padding-left: 8px;
	padding-right: 8px;
	color: #040406;
	box-sizing: border-box;

	@include breakpoint('xx-small') {
		padding-left: 16px;
		padding-right: 16px;
	}

	@include breakpoint('s-medium') {
		padding-left: 32px;
		padding-right: 32px;
	}
}

%purple_link {
	cursor: pointer;
	&:hover {
		color: $clr-purp;
		@media (hover: none) {
			color: inherit;
		}
	}
	&:active {
		color: $clr-purp;
	}
}

%underlined_link {
	cursor: pointer;
	color: $clr-purp;
	&:hover {
		@include hover('hover') {
			border-bottom: 1px solid $clr-purp;
		}
	}
}

%with_bullet {
	&:not(:first-child):before {
		content: $bullet;
		@include mr(4px);
		color: #d7d7d7;
		font-size: 10px;
		font-weight: normal;
		vertical-align: top;
	}
}

%with_dash {
	&:not(:first-child):before {
		content: $dash;
		@include mr(4px);
		color: rgba($clr-base, 0.24);
		font-size: 14px;
		line-height: 14px;
		font-weight: normal;
		vertical-align: top;
	}
}

%optionCheckIcon {
	display: inline-block;
	width: 12px;
	height: 12px;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxMiAxMiI+CiAgPHBhdGggZmlsbD0iIzA4QkY5NiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuNzAxOSAxLjgxOTQyYy4zOTM1LjQzMDU3LjM5ODQgMS4xMzQwNi4wMTA5IDEuNTcxM0w0LjgxOTU2IDExLjE2ODVjLS4zNTk2Ni40MDU4LS45MzcyNS40NDM3LTEuMzM3NDMuMDg3OEwuMzc1MzM0IDguNDkzMTdDLS4wNTU4MzMgOC4xMDk3LS4xMjU1OCA3LjQxMDQ2LjIxOTU0OCA2LjkzMTM5Yy4zNDUxMjktLjQ3OTA4Ljk3NDQ0Mi0uNTU2NTggMS40MDU2MDItLjE3MzFsMi40MDI0MyAyLjEzNjY5TDEwLjI4NzcgMS44MzE1Yy4zODc1LS40MzcyNCAxLjAyMDctLjQ0MjY1IDEuNDE0Mi0uMDEyMDhaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==');
}
