@import 'meta';
@import './adaptive/home.scss';

.fixer {
	padding-top: 4px;
	:global(.ls-modal-content) {
		margin-top: -4px;
		padding: 16px;
		max-width: 720px;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		@include breakpoint($from: 600, $to: 848) {
			max-width: calc(100vw - 128px);
		}
		@include breakpoint($to: 600) {
			max-width: calc(100vw - 32px);
			padding: 12px;
		}
		@include breakpoint($to: 375) {
			max-width: calc(100vw - 16px);
		}
	}

	.submit {
		@include mt(8px);
		@include breakpoint($to: 600) {
			@include mt(4px);
		}
	}
}

.title {
	position: relative;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
	color: #ffffff;
	@include mb(24px);
	padding-left: 16px;
	padding-right: 16px;
}

.item {
	@include mb(8px);
	:global(.filter-box-content.tag-cloud-x-4-6-x) {
		margin-bottom: 0;
	}
}

.submit {
	font-size: 18px;
	line-height: 20px;
	margin-top: 12px;
	:global(.button) {
		width: 100%;
	}
}

.submitBtn {
	height: 48px;
	&_modal {
		font-weight: 500;
		font-size: 16px;
	}
}

.formHolder {
	@include mt(4px);
	position: relative;
	z-index: 2;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 158px;
	padding-left: 8px;
	padding-right: 8px;

	&:after {
		content: '';
		height: 160px;
		width: 160px;
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		background-size: cover;
		background-image: url('/img/illustrations/ill-woman-220.svg');
		z-index: -1;
	}

	&_adapt {
		@include breakpoint($from: 375) {
			margin-top: 0;
			padding-left: 16px;
			padding-right: 16px;
		}
		@include breakpoint($from: 600) {
			padding-left: 24px;
			padding-right: 24px;
		}
		@include breakpoint($from: 768) {
			padding-bottom: 40px;
			padding-right: 380px;
			padding-left: 32px;
			margin-left: 0;
			max-width: 960px;
			margin-right: auto;
			box-sizing: border-box;

			&:after {
				width: 300px;
				height: 300px;
				left: auto;
				right: 32px;
				bottom: 68px;
				transform: none;
			}
		}
	}
}

.homeWrap {
	position: relative;
	z-index: 1;
	background: linear-gradient(0deg, #7147f9 0%, #6621e8 100%);
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		background-image: url('/img/home_form_bg.svg');
		background-size: cover;
		background-position: 50% 100%;
	}
}

.headerWrap {
	@extend %content_center;
	box-sizing: border-box;
}

.container {
	display: block;
	padding: 12px;
	background: #f2f2f3;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
	border-radius: 16px;
	box-sizing: border-box;
	width: 720px;
	max-width: 100%;
}

.homeWrap_adapt_home {
	@include adapt_home();
}
