@mixin adapt_default() {
	margin-left: -8px;
	margin-right: -8px;
	@include breakpoint($from: 375) {
		@include state_large();
		margin-left: -16px;
		margin-right: -16px;
	}
	@include breakpoint($from: 600) {
		// margin-left: -24px;
		// margin-right: -24px;
		.logo {
			margin-right: 16px;
		}
		.search {
			flex-grow: 0;
			margin-right: auto;
		}
	}
	:global(.--header-search) & {
		@include breakpoint($to: 600) {
			opacity: 0;
		}
	}
}
