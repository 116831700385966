@use 'conf';
@use 'abstracts/variables.scss';
@use 'abstracts/mixins.scss';
@use './states/theme_shadow.scss' as *;
@use './states/theme_stroke.scss' as *;
@use './states/theme_flat.scss' as *;
@use './states/appearance_select.scss' as *;
@use './states/placeholder_simple.scss' as *;
@use './states/placeholder_material.scss' as *;

$border-radius: 12px;

.block {
	position: relative;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 16px;
	width: 100%;
	height: 56px;

	border-radius: 12px;
	background-color: conf.$clr-invert;

	color: rgba(conf.$clr-base, 0.48);
	font-size: 16px;
	font-weight: 400;

	cursor: text;

	&_theme_shadow,
	/// @deprecated глобальный селектор
	&:global(.home) {
		@include state_theme_shadow;
	}

	&_theme_stroke {
		@include state_theme_stroke;
	}

	&_theme_flat,
	/// @deprecated глобальный селектор
	&:global(.modal-search) {
		@include state_theme_flat;
	}

	&_appearance_simple {
		.input::placeholder {
			color: rgba(4, 4, 6, 0.48);
			font-size: 16px;
			font-weight: 400;
			visibility: visible;
		}

		.placeholder {
			display: none;
		}
	}

	&_appearance_default {
		@include state_placeholder_material;
	}

	&_appearance_select {
		@include state_appearance_select;
	}

	&_appearance_select-material {
		@include state_appearance_select;
		@include state_placeholder_material;
	}

	&_error {
		border: 1px solid #ef1d4f;
	}
}

.icon {
	display: inline-flex;
	margin-left: 4px;
	margin-right: 20px;
}

.container {
	display: inline-flex;
	position: relative;

	width: 100%;
}

.input {
	width: 100%;
	text-overflow: ellipsis;

	background-color: transparent;

	color: conf.$clr-base;
	font-weight: 500;
	caret-color: variables.$clr-caret-home;

	transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

	&::placeholder {
		visibility: hidden;
		color: transparent;
	}
}

.placeholder {
	@include mixins.text-overflow();

	position: absolute;
	pointer-events: none;
	max-width: 100%;

	transform-origin: left;
	transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.append {
	display: inline-flex;
	margin-left: 20px;
}

.content {
	position: absolute;
	top: calc(100% + 8px);
	left: 0;
	right: 0;
}

.clear {
	display: flex;
	width: 20px;
	height: 20px;
	min-width: 20px;
	border-radius: 50%;
	background-color: #d7d9e0;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: pointer;
	&:hover {
		background-color: #ef1d4f;
	}
}

.closeIcon {
	position: relative;
	top: 0;
	width: 12px;
	height: 12px;
	fill: #fff;
}
