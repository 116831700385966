@mixin state_big() {
	&.block {
		width: 38px;
		height: 38px;
		&_filled {
			position: relative;
			padding: 0;
			margin: -1px;
		}

		.icon {
			margin: 0;
		}

		.count {
			position: absolute;
			right: 2px;
			top: -4px;
			transform: translateX(50%);
		}
	}
}
