@use 'conf';

$input-transform: translateY(8px);
$placeholder-transform: translateY(-11px) scale(0.75);

@mixin state_placeholder_material {
	.input {
		&:not(:placeholder-shown) {
			transform: $input-transform;

			+ .placeholder {
				transform: $placeholder-transform;
			}
		}
	}
}
