@import './mixins/device';

/// Mixin for `text-overflow: ellipsis`
/// @access public
/// @since 0.0.1
/// @group layout
/// @example scss - Default usage
///   @include text-overflow();
/// @output `overflow: hidden`, `text-overflow: ellipsis` and `white-space: nowrap`.
/// @author Artem Kucherenko

@mixin text-overflow($no-wrap: true) {
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: if($no-wrap, nowrap, null);
}

/// Mixin for disabling scrollbars
/// @author Artem Kucherenko
/// @group style
/// @link https://webkit.org/blog/363/styling-scrollbars/ WebKit
/// @link https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width FF
/// @output A set of properties for disabling scrollbars
@mixin disable-scrollbar {
	&::-webkit-scrollbar {
		// iOS Safari
		display: none;
		width: 0;
		height: 0;
	}

	// COF FF69
	scrollbar-width: none;
}

/// Mixin for grouping text related inputs.
/// @access public
/// @since 1.1.0
/// @group style
/// @example scss - Basic usage
///   @include input-type-text() {
///     padding: 4px 0;
///     background-color: transparent;
///   }
///
///   // [type="text"],
///   // [type="email"],
///   // [type="url"],
///   // [type="search"],
///   // [type="password"],
///   // [type="tel"] {
///   //   padding: 4px 0;
///   //   background-color: transparent;
///   // }
/// @output Text related inputs attribute selector.
/// @author Artem Kucherenko
/// @content [Everything]

@mixin input-type-text() {
	[type='text'],
	[type='email'],
	[type='url'],
	[type='search'],
	[type='password'],
	[type='tel'] {
		@content;
	}
}

/// Mixin for line clamping
/// @access public
/// @since 1.37.1
/// @group layout
/// @param {number} $line-num [2] - number of lines for clamping.
/// @example scss - Base usage
///   p {
///     @include line-clamp();
///   }
/// @output `-webkit`-prefixed properties for line clamping.
/// @author Artem Kucherenko
@mixin line-clamp($line-num: 2) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $line-num;
	overflow: hidden;
}

/// Helper-mixin for `CSSTransition` from `react-transition-group` classes.
/// @access public
/// @since 1.40.1
/// @group animations
/// @param {string|map} $name - transition's name, can be overloaded
/// @param {string} $prop [null] - transitioned CSS property name
/// @param {number} $enter [0] - prop's enter value
/// @param {number} $exit [0] - prop's exit value
/// @param {number} $delay [0] - duration to wait before starting a transition
/// @param {number|list|map} $duration [500ms] - transition duration
/// @param {string} $timing-function [null] - timing function, sets how intermediate values are calculated
/// @param {boolean} $opposite-functions [false] - sets `*-in` and `*-out` functions for `enter` and `exit` states
/// @param {string} $add-prop [null] - additional prop
/// @example scss - Basic usage
///   @include css-transition("fade", "opacity", 1, 0);
///   // .fade-appear,
///   // .fade-enter {
///   //   opacity: 1;
///   // }
///   // .fade-appear-active,
///   // .fade-enter-active,
///   // .fade-appear-done,
///   // .fade-enter-done {
///   //  opacity: 0;
///   //   transition: opacity 500ms 0;
///   // }
///   // .fade-exit {
///   //   opacity: 0;
///   // }
///   // .fade-exit-active,
///   // .fade-exit-done {
///   //   opacity: 1;
///   //   transition: opacity 500ms 0;
///   // }
/// @example scss - Overload usage
///   $fade-transition: ("name": "fade", "prop": "opacity", "enter": 1, "exit": 0);
///   @include css-transition($tag-filters-transition);
///   // .fade-appear,
///   // .fade-enter {
///   //   opacity: 1;
///   // }
///   // .fade-appear-active,
///   // .fade-enter-active,
///   // .fade-appear-done,
///   // .fade-enter-done {
///   //   opacity: 0;
///   //   transition: opacity 500ms 0;
///   // }
///   // .fade-exit {
///   //   opacity: 0;
///   // }
///   // .fade-exit-active,
///   // .fade-exit-done {
///   //   opacity: 1;
///   //   transition: opacity 500ms 0;
///   // }
/// @example scss - With additional prop
///   @include css-transition("slide-up", "transform", 100%, 0, $add-prop: "translateY");
///   // .slide-up-appear,
///   // .slide-up-enter {
///   //   transform: translateY(100%);
///   // }
///   // .slide-up-appear-active,
///   // .slide-up-appear-done,
///   // .slide-up-enter-active,
///   // .slide-up-enter-done {
///   //   transform: translateY(0);
///   //   transition: transform 500ms 0;
///   // }
///   // .slide-up-exit {
///   //   transform: translateY(0);
///   // }
///   // .slide-up-exit-active,
///   // .slide-up-exit-done {
///   //   transform: translateY(100%);
///   //   transition: transform 500ms 0;
///   // }
/// @example scss - Usage with easing and opposition functions
///   @include css-transition("fade", "opacity", 1, 0, 0, 500ms, "ease", true);
///   // .fade-appear,
///   // .fade-enter {
///   //   opacity: 1;
///   // }
///   // .fade-appear-active,
///   // .fade-appear-done,
///   // .fade-enter-active,
///   // .fade-enter-done {
///   //  opacity: 0;
///   //   transition: opacity 500ms ease-in 0;
///   // }
///   // .fade-exit {
///   //   opacity: 0;
///   // }
///   // .fade-exit-active,
///   // .fade-exit-done {
///   //   opacity: 1;
///   //   transition: opacity 500ms ease-out 0;
///   // }
/// @example scss - Multiple transitions
///   @include css-transition("fade-shrink", ("opacity", "width"), (1, 50px), 0, 0ms, 500ms);
///   // .fade-shrink-appear,
///   // .fade-shrink-enter {
///   //   opacity: 1;
///   //   width: 50px;
///   // }
///   // .fade-shrink-appear-active,
///   // .fade-shrink-appear-done,
///   // .fade-shrink-enter-active,
///   // .fade-shrink-enter-done {
///   //  opacity: 0;
///   //  width: 0;
///   //  transition: opacity 500ms ease 0ms, width 500ms ease 0ms;
///   // }
///   // .fade-exit {
///   //   opacity: 0;
///   //   width: 0;
///   // }
///   // .fade-exit-active,
///   // .fade-exit-done {
///   //   opacity: 1;
///   //   width: 50px;
///   //   transition: opacity 500ms ease 0ms, width 500ms ease 0ms;
///   // }
/// @output set of helper classes with #{$name}-* pattern.
/// @require {function} map-if-has-key - utility for overload mode
/// @todo multiple `$add-prop` support
/// @todo miltiple `$opposite-functions` support
/// @todo default units for unitless values
/// @author Artem Kucherenko
@mixin css-transition(
	$name,
	$prop: null,
	$enter: 0,
	$exit: 0,
	$delay: 0ms,
	$duration: 500ms,
	$timing-function: 'ease',
	$opposite-functions: false,
	$add-prop: null,
	$with-parent: false
) {
	$opposite-functions-map: (
		'ease': (
			'ease-in',
			'ease-out',
		),
	);

	$timing-in-function: null;
	$timing-out-function: null;

	@if type-of($name) == 'map' {
		$map: $name;

		$name: map-if-has-key($map, 'name', null);
		$prop: map-if-has-key($map, 'prop', null);
		$duration: map-if-has-key($map, 'duration', $duration);
		$add-prop: map-if-has-key($map, 'add-prop', $add-prop);
		$enter: map-if-has-key($map, 'enter', $enter);
		$exit: map-if-has-key($map, 'exit', $exit);
		$delay: map-if-has-key($map, 'delay', $delay);
		$timing-in-function: map-if-has-key(
			$map,
			'timing-function',
			$timing-function
		);
		$timing-out-function: map-if-has-key(
			$map,
			'timing-function',
			$timing-function
		);
		$opposite-functions: map-if-has-key(
			$map,
			'opposite-functions',
			$opposite-functions
		);
	}

	@if $opposite-functions {
		$timing-in-function: nth(
			map-get($opposite-functions-map, $timing-function),
			1
		);
		$timing-out-function: nth(
			map-get($opposite-functions-map, $timing-function),
			2
		);
	}

	@if (not $name) {
		@error "$name required";
	}

	@if (not $prop) {
		@error "$prop required";
	}

	$transition-in-string: #{$prop} $duration unquote($timing-in-function) $delay;
	$transition-out-string: #{$prop} $duration unquote($timing-out-function) $delay;
	$prop-enter-string: if($add-prop, unquote('#{$add-prop}(#{$enter})'), $enter);
	$prop-exit-string: if($add-prop, unquote('#{$add-prop}(#{$exit})'), $exit);

	@if (typeof $prop == 'list') {
		$transition-in-string: ();
		$transition-out-string: ();

		@each $p in $prop {
			$d-in: $duration;
			$d-out: $duration;

			@if (type-of($duration) == 'map') {
				$d-in: map-get(
					$map: $duration,
					$key: 'in',
				);
				$d-out: map-get(
					$map: $duration,
					$key: 'out',
				);
			}

			$d-in: if(type-of($d-in) == 'list', nth($d-in, index($prop, $p)), $d-out);

			$d-out: if(
				type-of($d-out) == 'list',
				nth($d-out, index($prop, $p)),
				$d-out
			);
			$tif: if(
				type-of($timing-in-function) == 'list',
				nth($timing-in-function, index($prop, $p)),
				$timing-in-function
			);
			$e: if(type-of($delay) == 'list', nth($delay, index($prop, $p)), $delay);
			$tof: if(
				type-of($timing-out-function) == 'list',
				nth($timing-out-function, index($prop, $p)),
				$timing-out-function
			);

			$transition-in-string: append(
				$transition-in-string,
				unquote(#{$p} #{$d-in} #{$tif} #{$e}),
				$separator: 'comma'
			);
			$transition-out-string: append(
				$transition-out-string,
				unquote(#{$p} #{$d-out} #{$tof} #{$e}),
				$separator: 'comma'
			);
		}
	}

	#{if($with-parent, selector-append("&", ".#{$name}-enter"), ".#{$name}-enter"),
	if($with-parent, selector-append("&", ".#{$name}-appear"), ".#{$name}-appear")} {
		@if (type-of($prop) == 'list') {
			@each $p in $prop {
				#{$p}: if(
					type-of($prop-enter-string) == 'list',
					nth($prop-enter-string, index($prop, $p)),
					$prop-enter-string
				);
			}
		} @else {
			#{$prop}: $prop-enter-string;
		}
	}

	#{if($with-parent, selector-append("&", ".#{$name}-enter-active"), ".#{$name}-enter-active"),
	if($with-parent, selector-append("&", ".#{$name}-appear-active"), ".#{$name}-appear-active"),
	if($with-parent, selector-append("&", ".#{$name}-appear-done"), ".#{$name}-appear-done"),
	if($with-parent, selector-append("&", ".#{$name}-enter-done"), ".#{$name}-enter-done"),
	} {
		@if (type-of($prop) == 'list') {
			@each $p in $prop {
				#{$p}: if(
					type-of($prop-exit-string) == 'list',
					nth($prop-exit-string, index($prop, $p)),
					$prop-exit-string
				);
			}
		} @else {
			#{$prop}: $prop-exit-string;
		}
		transition: $transition-in-string;
	}

	#{if($with-parent, selector-append("&", ".#{$name}-exit"), ".#{$name}-exit")} {
		@if (type-of($prop) == 'list') {
			@each $p in $prop {
				#{$p}: if(
					type-of($prop-exit-string) == 'list',
					nth($prop-exit-string, index($prop, $p)),
					$prop-exit-string
				);
			}
		} @else {
			#{$prop}: $prop-exit-string;
		}
	}

	#{if($with-parent, selector-append("&", ".#{$name}-exit-active"), ".#{$name}-exit-active"),
	if($with-parent, selector-append("&", ".#{$name}-exit-done"), ".#{$name}-exit-done")} {
		@if (type-of($prop) == 'list') {
			@each $p in $prop {
				#{$p}: if(
					type-of($prop-enter-string) == 'list',
					nth($prop-enter-string, index($prop, $p)),
					$prop-enter-string
				);
			}
		} @else {
			#{$prop}: $prop-enter-string;
		}
		transition: $transition-out-string;
	}
}

/// Примесь для корректной работы закрепленных снизу `sticky` элементов в Firefox.
/// @access public
/// @since 1.58.1
/// @group bugfix
/// @param {number} $height - высота `sticky` элемента.
/// @param {number} $margin - отступ сверху от `sticky` элемента.
/// @example scss - базовый пример
///   .block {
///     @include firefox-sticky-bottom(65px, 40px);
///   }
/// @output классы `.prev` (для элемента перед `sticky`) и `.sticky` (для самого `sticky`-элемента), которые необходимо использовать на соответствующих элементах.
/// @author Artem Kucherenko
@mixin firefox-sticky-bottom($height, $margin) {
	&::after {
		content: '';
		display: inline-block;
		width: 1px;
		height: $height;
		flex: 0 0 auto;
		visibility: hidden;
	}

	.prev {
		padding-bottom: $margin !important;
	}

	.sticky {
		margin-bottom: -$height !important;
		max-height: $height !important;
	}
}
