@use 'conf';

@mixin state_placeholder_simple {
	.placeholder {
		color: rgba(conf.$clr-base, 0.4);
	}

	&:focus-within .placeholder {
		color: rgba(conf.$clr-base, 0.16);
	}
}
