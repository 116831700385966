@use 'abstracts/mixins/breakpoint' as *;

@use './states/mobile.scss' as *;
@use './states/desktop.scss' as *;

.icon {
	&_adapt_responsive {
		@include breakpoint($to: 375) {
			@include state_mobile;
		}
		@include breakpoint($from: 375) {
			@include state_desktop;
		}
	}
}

.white {
	fill: #fff;
}
