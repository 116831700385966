@mixin state_medium() {
	.wrap {
		flex-direction: column;
		padding: 0;
		border: none;
		box-shadow: none;
		background: none;
	}

	.preview {
		margin-right: 0;
		flex-basis: auto;
		@include mb(12px);
	}

	.moreHolder {
		display: none;
	}

	.imageWrap {
		display: none;
	}

	.shortAddress {
		display: none;
	}

	.desc {
		display: none;
	}

	.slider {
		display: block;
	}

	.title {
		@include mb(8px);
	}

	.content {
		flex-basis: auto;
		padding: 0 8px;
	}

	.tags {
		margin-left: 0;
	}

	.favour {
		width: 24px;
		height: 22px;
		left: auto;
		right: 16px;
		top: 16px;
	}
}
