@use 'sass:math';
@import 'meta';
@import './states/big.scss';
@import './adaptive/header.scss';

.block {
	display: inline-flex;
	min-width: 30px;
	height: 32px;
	border-radius: 32px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&_empty {
		height: 30px;
		border: 1px solid rgba(4, 4, 6, 0.16);

		@include hover('hover') {
			&:hover {
				margin: -1px;
				border: 2px solid $clr-purp;
				.icon {
					fill: $clr-purp;
				}
			}
		}
		&:active {
			margin: -1px;
			border: 2px solid $clr-purp;
			background-color: $clr-purp;
			.icon {
				fill: #fff;
			}
		}
	}

	&_filled {
		padding: 0 8px;
		height: 28px;
		border: 2px solid #8835f4;
		@include hover('hover') {
			&:hover {
				.icon {
					fill: #8835f4;
				}
			}
		}
		&:active {
			background: #8835f4;
			.icon {
				fill: #fff;
			}
		}
	}
}

.icon {
	width: 16px;
	height: 16px;
	@include mr(5px);
}

.count {
	font-weight: 800;
	font-size: 12px;
	line-height: 1;
	border-radius: 32px;
	color: #fff;
	padding: 2px 4px;
	background: #8835f4;
	min-width: 16px;
	height: 16px;
	box-sizing: border-box;
}

.block_state_big {
	@include state_big();
}

.block_adapt_header {
	@include adapt_header();
}
