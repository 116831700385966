@mixin state_purple() {
	padding: 6px;
	border-radius: 21px;
	background-color: #fff;

	.item {
		border: 1px solid #04040652;

		&_active {
			background-color: #8835f4;
			border: 1px solid #8835f4;
		}

		&_transparent {
			background: none;
			border: 1px solid #04040652;
		}
	}
}
