@use 'sass:math';
@import 'meta';

.block {
	position: relative;
	display: block;
	@include hover('hover') {
		&:hover {
			.title {
				color: #8835f4;
			}
		}
	}
}

.ratio {
	&:before {
		display: block;
		content: '';
		padding-bottom: calc(160 / 140 * 100%);
		background-color: #f3f2fa;
		border-radius: 16px;
	}
}

.img {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 16px;
	width: 100%;
	height: auto;
	object-fit: cover;
}

.title {
	position: absolute;
	left: 12px;
	right: 12px;
	top: 12px;
	font-size: 12px;
	line-height: 12px;
	word-break: break-word;
}

.link {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 16px;
	cursor: pointer;
}
